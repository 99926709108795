@import '../../variables.scss';

.aboutSection {
  text-align: center;
  a {
    text-decoration: none;
    color: $light-color;
  }
}

.aboutSection__item {
  color: $primary-color;
  font-size: larger;
  font-weight: bolder;
}

.aboutSection__container {
  display: flex;
  flex-wrap: wrap;
}

.aboutSection__introduction {
  flex: 0 0 50%;
}

.aboutSection__avatar__image {
  width: 30%;
  height: auto;
}

.aboutSection__quote {
  background: #f9f9f9;
  border-left: 10px solid $secondary-color;
  margin: 1em 10px;
  padding: 0.5em 10px;
  quotes: '\201C''\201D''\2018''\2019';
}

.aboutSection__quote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.aboutSection__quote p {
  display: inline;
}

.background-image {
  background-image: url('../../images/background.jpg'); /* Replace with your image URL */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed; /* Keeps the background fixed when scrolling */
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  color: white;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Adds a semi-transparent background to the content */

  font-family: $font-family;
  font-size: 1rem;
  border-radius: 10px;
  width: 35%;
  padding: 20px; /* Padding for inner spacing */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  white-space: pre-wrap; /* Wrap text preserving whitespace */
}

@media screen and (max-width: 600px) {
  .aboutSection__container {
    flex-direction: column;
  }

  .aboutSection__introduction,
  .aboutSection__avatar {
    width: 100%;
  }

  .aboutSection__avatar__image {
    width: 80%;
    height: auto;
  }

  .background-image {
    background-attachment: scroll; /* On smaller devices, let the background scroll with the content */
  }

  .content {
    width: 90%;
    height: 60vh;
    font-size: 1rem;
  }
}
