@import '../../variables.scss';

* {
  box-sizing: border-box;
}

.shop__buy {
  display: flex;
  flex-direction: column;
  a {
    margin: 0.75em;
    text-decoration: none;
    font-family: $font-family;
    font-weight: bolder;
    color: $light-color;
    font-size: 3em;
  }

  a:hover {
    color: $secondary-color;
  }
}

.shop__merch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.shop__merch__img {
  width: 60%;
  height: auto;
}

@media screen and (max-width: 600px) {
  .shop__merch {
    flex-direction: column;
  }

  .shop__merch__img {
    width: 80%;
    height: auto;
  }

  .shop__buy {
    a {
      font-size: 2em;
    }
  }
}
