@import '../../variables.scss';

.songs__section {
  display: flex;
  flex-direction: column;
}

.songs__name {
  background: none;
  color: $tertitary-color;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;
}

.songs__name:hover {
  color: $tertitary-color;
}

.songs__lyric {
  background-color: #f5f7fa; /* Light background for readability */
  padding: 1em; /* Padding for inner spacing */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  color: #333; /* Dark text color for contrast */
  overflow-x: auto; /* Horizontal scroll for long content */
  white-space: pre-wrap; /* Wrap text preserving whitespace */
  line-height: 1.5; /* Line height for better readability */
  border: 1px solid #ccc; /* Border to distinguish the pre tag */
  margin: 1em 0; /* Margin for spacing around the element */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  font-family: $font-family;
}

.songs__lyric:hover {
  background-color: #e0e7ff; /* Change background on hover */
  color: #1a1a1a; /* Change text color on hover */
}

.songs__lyric code {
  font-family: 'Courier New', Courier, monospace; /* Ensure code font is monospaced */
  color: #d6336c; /* Color for code snippets */
}
