@import '../../variables.scss';

.musicWidget {
  display: flex;
  flex-direction: row;
  color: $dark-color;
  background-color: $dark-color;
}

.musicWidget__item {
  margin: 1em;
  flex-grow: 1;
  color: $dark-color;
}

@media screen and (max-width: 600px) {
  .musicWidget {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    background-position: right 35% bottom 90%;
  }

  .musicWidget__itemDescription {
    text-align: left;
    line-height: 1.5625em;
    margin: 1em 1em 2em 1em;
    font-style: italic;
    width: 100%;
  }
}
