$content-flex: 9;
$fontsize-p: 1rem;
$fontsize-nav: 1em;
$fontsize-nav-item: 1.5em;
$fontsize-nav-item-mobile: 1.2em;
$typing-font: 3rem;

$light-color: #ffffff;
$primary-color: teal;
$secondary-color: #e2bf23;
$tertitary-color: #3071e8ff;
$blood: red;
$light-gray: lightgray;
$contact-section-color: lightyellow;
$linkme-background: rgb(255 255 255 / 17%);
$dark-color: rgba(0, 0, 0, 0.87);

$font-family: 'NunitoSans';
$footer: $dark-color;
