@import './variables.scss';

@font-face {
  font-family: 'NunitoSans';
  src: local('NunitoSans'), url(./fonts/NunitoSans-Light.woff2) format('woff');
}

.app {
  font-family: $font-family;
  text-align: center;
}

.logo__container {
  display: flex;
  justify-content: center;
}

.logo {
  content: url(./images/logo.png);
  object-fit: cover;
  width: 10%;
  height: auto;
  margin: 1em;
}

h1,
h2,
h3 {
  color: $dark-color;
}
p {
  font-size: $fontsize-p;
  color: $dark-color;
  text-align: left;
  margin-left: 1em;
  margin-bottom: 1em;
}

body {
  padding: 0;
  margin: 0;
}

section {
  height: 100vh;
  padding: 2em;
  color: $light-color;
}

section:nth-of-type(1) {
  height: auto;
  background: $light-color;
}

section:nth-of-type(2) {
  background: $primary-color;
  height: auto;
}

section:nth-of-type(3) {
  height: auto;
  background: $light-color;
}

section:nth-of-type(4) {
  height: auto;
  background: $primary-color;
}

.selected {
  color: purple;
  font-weight: 900;
}

@media screen and (max-width: 600px) {
  .logo {
    content: url(./images/logo.png);
    object-fit: cover;
    width: 50%;
    height: auto;
  }
}
