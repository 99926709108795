@import '../../variables.scss';

.header {
  background-color: $dark-color;
  a {
    display: inline-block;
    margin: 1em 0;
    padding: 0 2em;
    text-decoration: none;
    color: $blood;
    font-family: $font-family;
    font-size: $fontsize-nav-item;
  }
}

.header__logoContainer {
  display: flex;
  justify-content: center;
  padding: 1em 1em 0em 1em;
  a {
    padding: 0.5em;
    margin: 0em;
  }
  img {
    width: 30vw;
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    background-color: $dark-color;
    a {
      padding: 0 1em;
      font-size: $fontsize-nav-item-mobile;
    }
  }

  .header__logoContainer {
    img {
      width: 85vw;
      height: auto;
    }
  }
}
