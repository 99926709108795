@import '../../variables.scss';

.social {
  display: flex;
  justify-content: center;
  max-width: 100vw;
}

.social__item {
  margin: 1em;
  font-size: large;
  a {
    color: $light-color;
  }

  svg:hover {
    transform: scale(1.15);
    color: $secondary-color;
  }
}

@media only screen and (max-width: 600px) {
  .social {
    max-width: 100vw;
  }
  .social__item {
    padding: 0.1em;
  }
}
